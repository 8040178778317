<template>
  <div class="contacts_block">
    <b-modal id="feedback" hide-footer size="lg">
      <div class="feedback-wrapper-modal">
        <div class="feedback-element">
          <h3>{{ $t("Leave your message for feedback") }}</h3>
        </div>
        <div class="feedback-element">
          <span>{{ $t("Full name") }}</span>
          <input v-model="data.name" />
        </div>
        <div class="feedback-element">
          <span>{{ $t("Phone") }}</span>
          <input v-model="data.phone" v-mask="'+7 (###) ### ##-##'" />
        </div>
        <div class="feedback-element">
          <span>{{ $t("Email") }}</span>
          <input v-model="data.email" />
        </div>
        <div class="feedback-element">
          <span>{{ $t("Message") }}</span>
          <textarea v-model="data.message" style="    border-radius: 10px;
    padding: 8px;"></textarea>
        </div>
        <div class="feedback-element">
          <vue-recaptcha @verify="toggleCaptcha" sitekey="6Le4a80jAAAAAIsLHciqcozvWuzy4bzyXl3FAX8R"></vue-recaptcha>
        </div>

        <div class="feedback-send">
          <el-button @click="send">{{ $t("Send") }}</el-button>
        </div>
      </div>
    </b-modal>
    <div class="contacts_main_block">
      <div class="contacts_main_container">
        <div class="contacts_main_title">{{ $t('header.contacts') }}</div>
        <div class="contacts_main_menu">
          <div class="contacts_main_menu_container">
            <div class="contacts_main_menu_group_left">
              <a href="mailto:support@birgeoqy.kz" class="contacts_main_menu_group_item_email">
                <div class="contacts_main_menu_group_item_img"/>
                <div class="contacts_main_menu_group_text">support@birgeoqy.kz</div>
              </a>
              <a href="https://www.instagram.com/olimpiada_elumiti.kz/" target="_blank"
                 class="contacts_main_menu_group_item_instagram">
                <div class="faq_main_menu_group_item_img"/>
                <div class="faq_main_menu_group_text">el_umiti.kz</div>
              </a>
            </div>

            <div class="contacts_main_menu_group_right">
              <a class="contacts_main_menu_group_item_phone" href="tel:87273100258">
                <div class="faq_main_menu_group_item_img"/>
                <div class="faq_main_menu_group_text contacts_phone_mobile">8 727 310 02 58</div>
              </a>
              <a href="https://t.me/+WPGK6KlglZs3NzZh" target="_blank"
                 class="contacts_main_menu_group_item_instagram">
                <div class="faq_main_menu_group_telegram_img"/>
                <div class="faq_main_menu_group_text">birgeoqykz</div>
              </a>
            </div>
          </div>
          <div class="contacts_main_menu_group_item_address">
            <div class="faq_main_menu_group_item_img"/>
            <div class="faq_main_menu_group_text">{{ $t('Contacts_address') }}</div>
            <div class="faq_main_menu_group_text">{{ $t('Contacts_address_fund') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="faq_support_block">
      <div class="faq_support_container">
        <div class="faq_support_form">
          <div class="faq_support_form_question">
            <div class="faq_support_form_question_img"/>
          </div>
          <div class="contacts_support_form_text_block">
            <div class="contacts_support_form_text_question">
              {{ $t('Do_you_have_suggestions_and_new_ideas') }}
            </div>
            <div class="faq_support_form_text_answer">
              {{ $t('Write_to_us') }}
            </div>
          </div>
          <a @click="$bvModal.show('feedback')" class="contacts_support_form_write">
            {{ $t('Write') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMask from 'v-mask'
import Vue from "vue";
import {VueRecaptcha} from "vue-recaptcha";

export default {
  name: "Contact",
  components: {VueRecaptcha},
  beforeCreate() {
    Vue.use(VueMask);
  },
  data() {
    return {
      captureButton: false,
      data: {
        name: '',
        phone: '',
        message: '',
        email: ''
      },
      currentLocale: this.$i18n.locale,
    };
  },
  methods: {
    toggleCaptcha() {
      this.captureButton = !this.captureButton;
    },
    send() {
      if (!this.captureButton) {
        Vue.toastr({
          description: 'Подтвердите что вы не робот',
          type: 'error'
        })
        return
      }

      function validateEmail(email) {
        var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(email).toLowerCase());
      }

      if (!validateEmail(this.data.email)) {
        Vue.toastr({
          description: 'Неверно указан имейл',
          type: 'error'
        })
        return
      }

      this.$http.post(`${API_ROOT}/api/feedback`, this.data)
        .then(() => {
          this.$bvModal.hide('feedback')
          this.data = {
            name: '',
            phone: '',
            message: '',
            email: ''
          };
          this.captureButton = false;

          Vue.toastr({
            description: 'Сообщение успешно отправлено',
            type: 'success'
          });
        })
        .catch(err => {
          this.captureButton = false;
          console.error(err);
        })
    },
  },

}
</script>


<style scoped>

</style>
